import React, { FormEvent, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import styles from "./ExperienceHomeBanner.module.scss";
import SubmitPopup from "../../shared/Form/SubmitPopup";
import { graphql, Link, useStaticQuery } from "gatsby";

interface ExperienceHomeBannerProps
{
    data: any;
}


const ExperienceHomeBanner = (props: PropsWithChildren<ExperienceHomeBannerProps>): ReactElement =>
{
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    );
    
    const {
        banner_title,
        banner_text,
        banner_background,
        banner_cta_text,
        banner_cta_url,
        banner_gradient_color,
        banner_text_color,
    } = props.data;
    
    const siteTitle = site.siteMetadata?.title;

    return (
        <section className={styles.ExperienceHomeBanner}  >
            <div className={styles.textContainer} >
                {banner_title[0] && <h2>{banner_title[0]?.text}<span> {siteTitle} </span></h2>}
                {banner_text[0] && <p>{banner_text[0]?.text}</p>}

                {banner_cta_text[0] && (
                    <Link to={(banner_cta_url && banner_cta_url.slug) ? `/${banner_cta_url.slug}` : "/try-us" } >
                    <button className={styles.formButton} >
                        <span className={styles.submitText} >{banner_cta_text[0]?.text || "Go"} </span>
                    </button>
                    </Link>
                )}
            </div>

            <div className={styles.imgContainer}>
                {banner_background && 
                    <img src={banner_background.url} alt={banner_background.alt ? banner_background.alt : ""} />
                }
            </div>

        </section>
    );
};

export default ExperienceHomeBanner;
